import { useQueryClient } from '@tanstack/react-query';
import { useSubscription } from 'urql';
import { useAuth } from '~/features/common/context/AuthContext';
import { logger } from '~/features/common/utils/logger';
import {
  GetJobQuery,
  GetMyJobsQuery,
  JobAddedDocument,
  JobAddedSubscription,
  JobRemovedDocument,
  JobRemovedSubscription,
  JobUpdatedDocument,
  JobUpdatedSubscription,
  JobVarsFragment,
  useGetMyJobsQuery,
} from '~/generated/graphql';

export interface JobListHookResult {
  jobs: JobVarsFragment[];
  isLoading: boolean;
  error: unknown;
}

export function useJobListForAssignee(assigneeID: string): JobListHookResult {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const { data, error, isLoading } = useGetMyJobsQuery(
    {
      userId: assigneeID,
    },
    { enabled: !!assigneeID && auth.isLoggedIn }
  );

  function handleJobAddedSubscription(
    _: undefined,
    response: JobAddedSubscription
  ) {
    queryClient.setQueryData<GetMyJobsQuery | undefined>(
      ['GetMyJobs', { userId: assigneeID }],
      (oldData: GetMyJobsQuery | undefined) => {
        logger.log('subscription jobAdded received data');
        if (response.jobAdded == null) {
          return oldData;
        } else if (
          !oldData?.jobs?.items?.some(
            (job) => job._id === response.jobAdded!._id
          )
        ) {
          queryClient.invalidateQueries(['GetMyJobs', { userId: assigneeID }]);
          queryClient.invalidateQueries([
            'GetRunSheetJobsBySessionAndContestant',
          ]);
          return {
            jobs: {
              items: [...(oldData?.jobs?.items ?? []), response.jobAdded],
            },
          };
        }
        return oldData;
      }
    );
    return undefined;
  }

  useSubscription(
    {
      query: JobAddedDocument,
      variables: { assigneeID },
    },
    handleJobAddedSubscription
  );

  function handleJobUpdatedSubscription(
    _: undefined,
    response: JobUpdatedSubscription
  ) {
    queryClient.setQueryData<GetMyJobsQuery | undefined>(
      ['GetMyJobs', { assigneeID: assigneeID }],
      (oldData: GetMyJobsQuery | undefined) => {
        logger.log('subscription jobUpdated received data');
        if (response.jobUpdated == null) {
          return oldData;
        }
        queryClient.setQueryData<GetJobQuery>(
          ['GetJob', { id: response.jobUpdated._id }],
          () => ({ job: response.jobUpdated })
        );
        queryClient.invalidateQueries(['GetMyJobs', { userId: assigneeID }]);
        queryClient.invalidateQueries([
          'GetRunSheetJobsBySessionAndContestant',
        ]);

        if (!oldData?.jobs?.items) {
          return {
            jobs: {
              items: [response.jobUpdated],
            },
          };
        }

        return {
          jobs: {
            items: oldData.jobs.items.map((job) => {
              if (job._id === response.jobUpdated!._id) {
                return response.jobUpdated!;
              }
              return job;
            }),
          },
        };
      }
    );
    return undefined;
  }

  useSubscription(
    {
      query: JobUpdatedDocument,
      variables: { assigneeID: assigneeID },
    },
    handleJobUpdatedSubscription
  );

  function handleJobRemovedSubscription(
    _: undefined,
    response: JobRemovedSubscription
  ) {
    queryClient.setQueryData<GetMyJobsQuery | undefined>(
      ['GetMyJobs', { assigneeID: assigneeID }],
      (oldData: GetMyJobsQuery | undefined) => {
        logger.log('subscription jobRemoved received data');
        if (response.jobRemoved == null) {
          return oldData;
        }
        queryClient.invalidateQueries(['GetMyJobs', { userId: assigneeID }]);
        queryClient.invalidateQueries([
          'GetRunSheetJobsBySessionAndContestant',
        ]);

        if (!oldData?.jobs?.items) {
          return oldData;
        }

        return {
          jobs: {
            items: oldData.jobs.items.filter(
              (job) => job._id !== response.jobRemoved!._id
            ),
          },
        };
      }
    );
    return undefined;
  }

  useSubscription(
    {
      query: JobRemovedDocument,
      variables: { assigneeID: assigneeID },
    },
    handleJobRemovedSubscription
  );

  return {
    jobs: data?.jobs?.items ?? [],
    error,
    isLoading,
  };
}
