import clsx from 'clsx';
import { useState } from 'react';
import { cn } from '~/features/common/utils/tailwind';
import config from '../../../../config';
import { PaceteqLogo } from '../../assets/PaceteqLogo';
import { hasRequiredRoles } from '../../utils/loginChecks';
import ApiVersionCheck from './ApiVersionCheck';
import ClientVersionCheck from './ClientVersionCheck';
import NavCategory from './components/NavCategory';
import OneRaceHubLogo from './components/OneRaceHubLogo';
import ServerStatus from './components/ServerStatus';
import SidebarCollapseButton from './components/SidebarCollapseButton';
import SidebarItem from './components/SidebarItem';
import {
  aiAndMl,
  configuration,
  homeItem,
  manager,
  operations,
  partsAndSpecs,
  useProfile,
} from './components/SidebarNavigationLinks';
import { TimeRemaining } from './components/TimeRemaining';
import { TimingServiceStatusIndicator } from './components/TimingServiceStatusIndicator';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isHelpEnabled] = useState(false);
  const profile = useProfile();

  const mainMenuCategories = [
    operations(),
    manager(),
    aiAndMl(),
    partsAndSpecs(),
    configuration(),
  ];

  return (
    <div
      className={clsx(
        collapsed ? 'w-[3rem]' : 'w-56x w-[230px]',
        'flex flex-col',
        'print:hidden'
      )}
    >
      <div className="flex h-0 flex-1 flex-col border-r border-gray-200 bg-gray-50/10 dark:border-gray-800 dark:bg-gray-900">
        <div
          className={clsx(
            collapsed ? 'pt-3' : ' pb-4 pt-3',
            'flex flex-1 flex-col overflow-y-auto overflow-x-hidden'
          )}
        >
          <OneRaceHubLogo collapsed={collapsed} />
          <div
            className={clsx(
              'ailgn-end mt-2 flex flex-wrap justify-start gap-x-1 gap-y-1.5',
              collapsed ? 'px-1' : 'px-3'
            )}
          >
            <TimingServiceStatusIndicator collapsed={collapsed} />
            {config.get('session.showSessionTimeRemaining') === true && (
              <TimeRemaining />
            )}
            <ServerStatus collapsed={collapsed} />
          </div>
          <ApiVersionCheck collapsed={collapsed} />
          <ClientVersionCheck collapsed={collapsed} />
          <nav className="mt-2 flex-1" aria-label="Sidebar">
            <SidebarItem
              item={homeItem}
              collapsed={collapsed}
              isHelpEnabled={isHelpEnabled}
            />
            {mainMenuCategories.map((clusterItem, index) => {
              return (
                <NavCategory
                  key={clusterItem.name ?? index}
                  collapsed={collapsed}
                  item={clusterItem}
                  isHelpEnabled={isHelpEnabled}
                />
              );
            })}
          </nav>
        </div>
        <div className="mx-2.5 my-1.5 flex items-center justify-between">
          <PaceteqLogo
            classNames={cn('w-[110px] h-[25px]', {
              'w-[25px]': collapsed,
            })}
            testId={''}
            collapsed={collapsed}
          />
          {/* {!collapsed && (
            <HelpToggle
              isHelpEnabled={isHelpEnabled}
              setIsHelpEnabled={setIsHelpEnabled}
            />
          )} */}
        </div>
        <div className="border-t border-gray-300 py-0.5  hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-800">
          {profile.children.map(
            (item) =>
              hasRequiredRoles(item.restrictToRoles) && (
                <SidebarItem
                  key={item.name}
                  item={item}
                  collapsed={collapsed}
                  isHelpEnabled={isHelpEnabled}
                />
              )
          )}
        </div>
        <SidebarCollapseButton
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
    </div>
  );
};

export default Sidebar;
