// ROUTES
import { getIndexPath as getChassisPath } from '~/features/admin/chassis/routes';
import { getIndexPath as getContestantsPath } from '~/features/admin/contestants/routes';
import { getIndexPath as getDepartmentsPath } from '~/features/admin/departments/routes';
import { getIndexPath as getRaceSeriesListPath } from '~/features/admin/event-manager/race-series/routes';
import { getIndexPath as getLocationsPath } from '~/features/admin/locations/routes';
import { getIndexPath as getManufacturersPath } from '~/features/admin/manufacturers/routes';
import { getIndexPath as getPersonsPath } from '~/features/admin/persons/routes';
import { getIndexPath as getPointsSystemPath } from '~/features/admin/points-system/routes';
import { getIndexPath as getTeamsPath } from '~/features/admin/teams/routes';
import { getIndexPath as getTracksPath } from '~/features/admin/tracks/routes';
import { getIndexPath as getUserEnumsPath } from '~/features/admin/user-enums/routes';
import { getIndexPath as getSetupOverviewPath } from '~/features/analysis/car-overview/routes';
import { getIndexPath as getKPIOverviewPath } from '~/features/analysis/kpi-overview/routes';
import { getIndexPath as getAssemblyPath } from '~/features/assemblies-parts/assemblies/routes';
import { getIndexPath as getPartsPath } from '~/features/assemblies-parts/parts/routes';
import { getIndexPath as getCalendarPath } from '~/features/calendar/routes';
import { getIndexPath as getIssueManagerPath } from '~/features/issue-manager/routes';
import { getIndexPath as getEventJobsPath } from '~/features/jobs/event-jobs/routes';
import { getIndexPath as getMyJobsPath } from '~/features/jobs/my-jobs/routes';
import { getJobListTemplatesPath } from '~/features/jobs/routes';
import { getIndexPath as getProfilePath } from '~/features/profile/routes';
import { getIndexPath as getProtocolDashboardPath } from '~/features/protocol/dashboard/routes';
import { getIndexPath as getRunComparePath } from '~/features/protocol/run-compare/routes';
import { getIndexPath as getRunPlannerPath } from '~/features/protocol/run-planner/routes';
import { getIndexPath as getRunSheetsPath } from '~/features/protocol/runsheets/routes';
import { getIndexPath as getSetupSheetsMainViewPath } from '~/features/protocol/setupsheets/routes';
import { getIndexPath as getSimulationPath } from '~/features/simulation/routes';
import { getIndexPath as getSpecOverviewPath } from '~/features/specifications/routes';
import { getIndexPath as getCarDataServicePath } from '~/features/system/agents/car-data-service/routes';
import { getIndexPath as getMultipleEventsControllerPath } from '~/features/system/agents/timing-service/routes';
import { getIndexPath as getWeatherServicePath } from '~/features/system/agents/weather/routes';
import { getIndexPath as getDataOverviewPath } from '~/features/system/server-data/data-overview/setup-sheets/routes';
import { getIndexPath as getOfflineStatusPath } from '~/features/system/server-data/offline-status/routes';
import { getIndexPath as getSsoProviderPath } from '~/features/system/server-data/sso/routes';
import { getIndexPath as getAssemblyTemplatesPath } from '~/features/templates/assembly-templates/routes';
import { getIndexPath as getBuildSheetsPath } from '~/features/templates/build-sheet-styles/routes';
import { getIndexPath as getCalculationsPath } from '~/features/templates/calculations/routes';
import { getIndexPath as getPackagesPath } from '~/features/templates/packages/routes';
import { getIndexPath as getRunSheetStylesPath } from '~/features/templates/runsheet-styles/routes';
import { getIndexPath as getSetupSheetStylesPath } from '~/features/templates/setup-sheet-styles/routes';
import { getIndexPath as getTyrePressureCalculationPath } from '~/features/tyres/tyrepressure-calculation/routes';
import { getIndexPath as getEventTyresPath } from '~/features/tyres/tyresheet/event-tyres/routes';
import { getIndexPath as getWeatherPath } from '~/features/weather/routes';

import {
  getTimeSheetExportPath,
  getTimeTrackerAdminPath,
  getIndexPath as getTimeTrackerPath,
} from '~/features/timetracker/routes';

// OTHER IMPORTS
import {
  faCalendarAlt,
  faCalendarCheck,
  faClipboard,
  faFileLines,
  faObjectUngroup,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import {
  faChartLine,
  faCloudSun,
  faCoins,
  faHome,
  faLaptop,
  faNetworkWired,
  faSlidersH,
  faTasks,
  faTruckMonster,
  faUser,
  faWalkieTalkie,
  faWarning,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import config from '~/config';
import { faRaceHelmet } from '~/features/common/assets/customIcons/faRaceHelmet';
import { useAuth } from '~/features/common/context/AuthContext';
import { resetConfigValues } from '~/features/common/utils/resetConfigValues';
import { useThemeContext } from '~/features/common/utils/services/HandleThemeToggling';
import {
  EnumPersonRole,
  useGetPersonInfoForProfilePageQuery,
  useGetPersonInfoQuery,
  useLicenseQuery,
} from '~/generated/graphql';
import { queryClient } from '~/graphql/queryClient';
import { urqlReinitialize } from '~/graphql/urqlClient';
import { exportDbToJson } from '~/indexDB';
import { getUserID, hasRequiredRoles } from '../../../utils/loginChecks';

type RequiredFeature =
  | 'dyno'
  | 'moveServer'
  | 'readOnlyAfterLicenseExpiry'
  | 'sevenPost'
  | 'simulation'
  | 'teams'
  | 'tyreSetStatistics'
  | 'lightVersion';

const allRolesExceptDriver = [
  EnumPersonRole.ADMIN,
  EnumPersonRole.CAR_ENGINEER,
  EnumPersonRole.CHIEF_ENGINEER,
  EnumPersonRole.FAULT_ADMIN,
  EnumPersonRole.MANAGEMENT,
  EnumPersonRole.MECHANIC,
  EnumPersonRole.READ_ONLY,
  EnumPersonRole.SETUP_REVIEWER,
  EnumPersonRole.TYRE_ENGINEER,
  EnumPersonRole.WORKSHOP_MANAGEMENT,
];
const allRoles = [...allRolesExceptDriver, EnumPersonRole.DRIVER];

export type RequiredFeatures = RequiredFeature[];

export type SidebarChild = {
  name: string;
  link?: string;
  isactive: boolean;
  availableInLightVersion: boolean;
  restrictToRoles?: EnumPersonRole[];
  restrictToLicense?: RequiredFeatures;
  restrictByFunction?: ({
    person,
  }: {
    person?: {
      isTimeTracker?: boolean | null;
      isTimeTrackerAdmin?: boolean | null;
      hideKPIs?: boolean | null;
    } | null;
  }) => boolean;
  action?: () => void;
  children?: SidebarChild[];
  helpLink?: string;
};

export type SideBarNavigation = {
  name: string;
  href: string | null;
  icon: IconDefinition;
  isactive: boolean;
  availableInLightVersion: boolean;
  openChildrenWithHoverOnly?: boolean;
  restrictToRoles?: EnumPersonRole[];
  action?: () => void;
  children?: SidebarChild[];
  restrictByFunction?: ({
    person,
  }: {
    person?: {
      isTimeTracker?: boolean | null;
      isTimeTrackerAdmin?: boolean | null;
      hideKPIs?: boolean | null;
    } | null;
  }) => boolean;
};

export type SideBarCategory = {
  name: string;
  children: SideBarNavigation[];
  availableInLightVersion?: boolean;
};

// careful we call everything with CTRL+SHIFT so lowercase will not work!
export const navigationShortcutList: { [key: string]: (params: {}) => string } =
  {
    C: getSetupOverviewPath,
    J: getMyJobsPath,
    K: getKPIOverviewPath,
    R: getRunSheetsPath,
    S: getSimulationPath,
    Y: getEventTyresPath,
    W: getWeatherPath,
  };

export const homeItem = {
  name: 'Home',
  href: '/',
  icon: faHome,
  isactive: true,
  availableInLightVersion: true,
};

export const operations = (): SideBarCategory => ({
  name: 'Operations',
  availableInLightVersion: true,
  children: [
    {
      name: 'Protocol',
      href: getRunSheetsPath({}),
      icon: faClipboard,
      isactive: true,
      availableInLightVersion: true,
      children: [
        {
          name: 'Run Sheet',
          link: getRunSheetsPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRoles,
        },
        {
          name: 'Run Compare',
          link: getRunComparePath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'Run Planner',
          link: getRunPlannerPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'Setup Sheets',
          link: getSetupSheetsMainViewPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'Dashboard',
          link: getProtocolDashboardPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRoles,
        },
      ],
    },
    {
      name: 'Tyres',
      href: getEventTyresPath({}),
      icon: faCoins,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,

      children: [
        {
          name: 'Tyre Sheet',
          link: getEventTyresPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'Pressure Calculation',
          link: getTyrePressureCalculationPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
      ],
    },
    {
      name: 'Analysis',
      href: getSetupOverviewPath({}),
      icon: faChartLine,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,

      children: [
        {
          name: 'Car Overview',
          link: getSetupOverviewPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'KPI Dashboard',
          link: getKPIOverviewPath({}),
          restrictToRoles: [
            EnumPersonRole.ADMIN,
            EnumPersonRole.MANAGEMENT,
            EnumPersonRole.CAR_ENGINEER,
            EnumPersonRole.CHIEF_ENGINEER,
            EnumPersonRole.MECHANIC,
            EnumPersonRole.DRIVER,
          ],
          restrictByFunction: ({ person }) => !person?.hideKPIs,
          isactive: true,
          availableInLightVersion: false,
        },
      ],
    },
    {
      name: 'Driver',
      href: `DriverRoute`,
      icon: faRaceHelmet as IconDefinition,
      isactive: false,
      availableInLightVersion: true,

      children: [
        {
          name: 'Feedback',
          link: ``,
          isactive: false,
          availableInLightVersion: true,
        },
        {
          name: 'Track Walk',
          link: ``,
          isactive: false,
          availableInLightVersion: true,
        },
      ],
    },
    {
      name: 'Jobs',
      href: getEventJobsPath({}),
      icon: faTasks,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,

      children: [
        {
          name: 'All Jobs',
          link: getEventJobsPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'My Jobs',
          link: getMyJobsPath({}),
          isactive: true,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
      ],
    },
    {
      name: 'Simulation',
      href: getSimulationPath({}),
      icon: faLaptop,
      isactive: true,
      availableInLightVersion: false,
      restrictToRoles: allRolesExceptDriver,
    },
    {
      name: 'Weather',
      href: getWeatherPath({}),
      icon: faCloudSun,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,
    },
  ],
});

export const aiAndMl = (): SideBarCategory => ({
  name: 'AI & ML',
  availableInLightVersion: false,
  children: [
    {
      name: 'Transcribble',
      href: ``,
      icon: faWalkieTalkie,
      isactive: false,
      availableInLightVersion: false,
      restrictToRoles: allRolesExceptDriver,
    },
  ],
});

export const manager = (): SideBarCategory => ({
  name: 'Manager',
  availableInLightVersion: true,
  children: [
    {
      name: 'Calendar',
      href: getCalendarPath({}),
      icon: faCalendarAlt,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,
    },
    {
      name: 'Documents',
      href: '',
      icon: faFileLines,
      isactive: false,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,
      children: [
        {
          name: 'Notes',
          link: '',
          isactive: false,
          availableInLightVersion: true,
          restrictToRoles: allRolesExceptDriver,
        },
      ],
    },
    {
      name: 'Issue Manager',
      href: getIssueManagerPath({}),
      icon: faWarning,
      isactive: true,
      availableInLightVersion: true,
      restrictToRoles: allRolesExceptDriver,
    },
    {
      name: 'Time Tracker',
      href: getTimeTrackerPath({}),
      icon: faCalendarCheck,
      //TODO this restrictions seems to not work, yet
      restrictByFunction: ({ person }) => Boolean(person?.isTimeTracker),
      isactive: true,
      availableInLightVersion: false,
      restrictToRoles: allRolesExceptDriver,

      children: [
        {
          name: 'Tracker',
          link: getTimeTrackerPath({}),
          isactive: true,
          availableInLightVersion: false,
          restrictByFunction: ({ person }) => Boolean(person?.isTimeTracker),
        },
        {
          name: 'Export',
          link: getTimeSheetExportPath({}),
          isactive: true,
          availableInLightVersion: false,
          restrictByFunction: ({ person }) =>
            Boolean(person?.isTimeTrackerAdmin),
        },
        {
          name: 'Admin',
          link: getTimeTrackerAdminPath({}),
          isactive: true,
          availableInLightVersion: false,
          restrictByFunction: ({ person }) =>
            Boolean(person?.isTimeTrackerAdmin),
        },
      ],
    },
  ],
});

export const partsAndSpecs = (): SideBarCategory => ({
  name: 'Parts & Specs',
  availableInLightVersion: false,
  children: [
    {
      name: 'Parts & Assemblies',
      href: getAssemblyPath({}),
      icon: faWrench,
      isactive: true,
      availableInLightVersion: false,
      restrictToRoles: allRolesExceptDriver,
      children: [
        {
          name: 'Assemblies',
          link: getAssemblyPath({}),
          isactive: true,
          availableInLightVersion: false,
          restrictToRoles: allRolesExceptDriver,
        },
        {
          name: 'Parts',
          link: getPartsPath({}),
          isactive: true,
          availableInLightVersion: false,
          restrictToRoles: allRolesExceptDriver,
        },
      ],
    },
    {
      name: 'Specifications',
      href: getSpecOverviewPath({}),
      icon: faTruckMonster,
      isactive: true,
      availableInLightVersion: false,
      restrictToRoles: allRolesExceptDriver,
    },
  ],
});

export const configuration: () => SideBarCategory = () => {
  const conf: SideBarCategory = {
    name: 'Configuration',
    availableInLightVersion: true,
    children: [
      {
        name: 'Admin',
        href: getPersonsPath({}),
        icon: faSlidersH,
        restrictToRoles: [
          EnumPersonRole.ADMIN,
          EnumPersonRole.CAR_ENGINEER,
          EnumPersonRole.MANAGEMENT,
        ],
        isactive: true,
        availableInLightVersion: true,
        children: [
          {
            name: 'Chassis',
            link: getChassisPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Contestants',
            link: getContestantsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Departments',
            link: getDepartmentsPath({}),
            isactive: true,
            availableInLightVersion: false,
          },
          {
            name: 'Events',
            link: getRaceSeriesListPath({}),
            isactive: true,
            availableInLightVersion: true,
            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CAR_ENGINEER,
              EnumPersonRole.CHIEF_ENGINEER,
              EnumPersonRole.MANAGEMENT,
              EnumPersonRole.SETUP_REVIEWER,
            ],
          },
          {
            name: 'Locations',
            link: getLocationsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Manufacturers',
            link: getManufacturersPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Points Systems',
            link: getPointsSystemPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Teams',
            link: getTeamsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Tracks',
            link: getTracksPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Users / Drivers',
            link: getPersonsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'User Enums',
            link: getUserEnumsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
        ],
      },
      {
        name: 'Templates',
        href: getCalculationsPath({}),
        icon: faObjectUngroup,
        availableInLightVersion: true,
        restrictToRoles: allRolesExceptDriver,
        isactive: true,
        children: [
          {
            name: 'Assembly Templates',
            link: getAssemblyTemplatesPath({}),
            availableInLightVersion: true,

            isactive: true,
            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CAR_ENGINEER,
              EnumPersonRole.CHIEF_ENGINEER,
            ],
          },
          {
            name: 'Build Sheet Layouts',
            link: getBuildSheetsPath({}),
            availableInLightVersion: true,

            isactive: true,
            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CAR_ENGINEER,
              EnumPersonRole.CHIEF_ENGINEER,
            ],
          },
          {
            name: 'Calculations',
            link: getCalculationsPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Job List Templates',
            link: getJobListTemplatesPath({}),
            availableInLightVersion: true,

            isactive: true,
            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CAR_ENGINEER,
              EnumPersonRole.CHIEF_ENGINEER,
              EnumPersonRole.MANAGEMENT,
              EnumPersonRole.WORKSHOP_MANAGEMENT,
            ],
          },
          {
            name: 'Packages',
            link: getPackagesPath({}),
            availableInLightVersion: true,
            isactive: true,
            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CAR_ENGINEER,
              EnumPersonRole.CHIEF_ENGINEER,
              EnumPersonRole.MANAGEMENT,
              EnumPersonRole.WORKSHOP_MANAGEMENT,
            ],
          },
          {
            name: 'Run Sheet Layouts',
            link: getRunSheetStylesPath({}),
            availableInLightVersion: true,

            restrictToRoles: [
              EnumPersonRole.ADMIN,
              EnumPersonRole.CHIEF_ENGINEER,
            ],
            isactive: true,
          },
          {
            name: 'Setup Sheet Layouts',
            link: getSetupSheetStylesPath({}),
            isactive: true,
            availableInLightVersion: true,
          },
        ],
      },
      {
        name: 'System',
        href: getCarDataServicePath({}),
        isactive: true,
        availableInLightVersion: true,
        restrictToRoles: allRolesExceptDriver,
        icon: faNetworkWired,
        children: [
          {
            name: 'Agents',
            link: getCarDataServicePath({}),
            availableInLightVersion: true,
            isactive: true,
            children: [
              {
                name: 'Car Data Service',
                link: getCarDataServicePath({}),
                availableInLightVersion: true,
                restrictToRoles: [
                  EnumPersonRole.ADMIN,
                  EnumPersonRole.CHIEF_ENGINEER,
                ],
                isactive: true,
                helpLink:
                  'https://support.paceteq.com/oneracehub/car-data-service-configuration',
              },
              {
                name: 'Timing Service',
                link: getMultipleEventsControllerPath({}),
                isactive: true,
                availableInLightVersion: true,
              },

              {
                name: 'Weather Service',
                link: getWeatherServicePath({}),
                isactive: true,
                availableInLightVersion: true,
              },
            ],
          },
          {
            name: 'Server / Data',
            link: getDataOverviewPath({}),
            isactive: true,
            availableInLightVersion: true,

            children: [
              {
                name: 'Database',
                link: getDataOverviewPath({}),
                isactive: true,
                availableInLightVersion: true,
              },
              {
                name: 'Offline Status',
                link: getOfflineStatusPath({}),
                isactive: true,
                availableInLightVersion: true,
              },
            ],
          },
        ],
      },
    ],
  };
  const allowedSsoServers = ['localhost', 'dev', 'rfk'];
  if (allowedSsoServers.some((s) => config.get('api.address').includes(s))) {
    conf
      .children!.find((e) => e.name === 'System')!
      .children!.find((e) => e.name === 'Server / Data')!
      .children!.push({
        name: 'SSO',
        link: getSsoProviderPath({}),
        isactive: true,
        availableInLightVersion: false,
      });
  }

  return conf;
};

export const useProfile = (): SideBarCategory => {
  const auth = useAuth();
  const { toggleTheme } = useThemeContext();
  const personID = getUserID();
  const { isLoading, data } = useGetPersonInfoForProfilePageQuery(
    { id: personID },
    { enabled: !!personID }
  );
  return {
    name: 'Profile/ System',
    children: [
      {
        name: `${isLoading ? 'Account' : data?.person?.username}`,
        icon: faUser,
        href: getProfilePath({}),
        isactive: true,
        openChildrenWithHoverOnly: true,
        availableInLightVersion: true,
        children: [
          {
            name: 'Profile',
            link: getProfilePath({}),
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Logout',
            isactive: true,
            action: () => {
              resetConfigValues();
              auth.signout();
            },
            availableInLightVersion: true,
          },
          {
            name: 'Reload',
            action: () => {
              urqlReinitialize();
              queryClient.invalidateQueries();
            },
            isactive: true,
            availableInLightVersion: true,
          },
          {
            name: 'Switch Light/Dark',
            isactive: true,
            availableInLightVersion: true,
            action: () => toggleTheme(),
          },
          {
            name: 'Export Debug File',
            isactive: true,
            availableInLightVersion: true,
            action: () => exportDbToJson(),
          },
        ],
      },
    ],
  };
};

export const useAvailableMenuChildren = (
  children: SidebarChild[] | undefined
) => {
  const personID = getUserID();
  const { data: license } = useLicenseQuery({}, { staleTime: Infinity });
  const { data } = useGetPersonInfoQuery(
    { id: personID },
    { enabled: !!personID, staleTime: Infinity }
  );

  const hasRequiredLicenseForMenuEntry = (child: SidebarChild) =>
    !child.restrictToLicense ||
    child.restrictToLicense.every(
      (requiredFeature) => !!license?.license?.features[requiredFeature]
    );

  const hasRequiredRoleForMenuEntry = (child: SidebarChild) =>
    hasRequiredRoles(child.restrictToRoles);

  const sufficesAdvancedRestrictions = (child: SidebarChild) =>
    !child.restrictByFunction ||
    child.restrictByFunction({ person: data?.person });

  const availableChildren = (children ?? [])
    .filter(hasRequiredLicenseForMenuEntry)
    .filter(hasRequiredRoleForMenuEntry)
    .filter(sufficesAdvancedRestrictions);

  return availableChildren;
};
